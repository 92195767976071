import { sale } from "../../constants/actionTypes";

const initialState = {
  saleInfo: null,
  sale_details: null,
  allSales: null,
  selectedSales: null,
};

function saleReducer(state = initialState, action) {
  switch (action.type) {
    case sale.SELECTED_SALE:
      return { ...state, selectedSales: action.payload };
    case sale.SALE_INFO:
      return { ...state, expenseInfo: action.payload };
    case sale.SALES:
      return { ...state, allSales: action.payload };
    case sale.DELETE_SALE:
      return { ...state, selectedExpense: action.payload };
    default:
      return state;
  }
}
export default saleReducer;

import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/icons/logo.jpg";

export default function Sidebar({ nav, onChangeNav }) {
  return (
    <>
      <a id="show-sidebar" className="btn btn-lg" href="#">
        <i className="fas fa-bars"></i>
      </a>
      <nav id="sidebar" className="sidebar-wrapper">
        <div className="sidebar-content">
          <div className="sidebar-brand">
            <div id="close-sidebar">
              <i className="fas fa-times"></i>
            </div>
          </div>
          <div className="sidebar-menu">
            <ul>
              <li className="header-menu" style={{ height: "30px" }}></li>
              <li className={nav === "/dashboard" ? "sidenav-active" : ""}>
                <Link
                  to="/dashboard"
                  onClick={() => onChangeNav("/dashboard")}
                  href="#"
                >
                  <i className="fa fa-tachometer" />
                  <span>Reports</span>
                </Link>
              </li>
              <li className={nav === "/users" ? "sidenav-active" : ""}>
                <Link
                  to="/users"
                  onClick={() => onChangeNav("/users")}
                  href="#"
                >
                  <i className="fa fa-users" />
                  <span>Users</span>
                </Link>
              </li>
              <li className={nav === "/payments" ? "sidenav-active" : ""}>
                <Link
                  to="/payments"
                  onClick={() => onChangeNav("/payments")}
                  href="#"
                >
                  <i className="fa fa-play" />
                  <span>Payments</span>
                </Link>
              </li>

              <li className={nav === "/expenses" ? "sidenav-active" : ""}>
                <Link
                  to="/expenses"
                  onClick={() => onChangeNav("/expenses")}
                  href="#"
                >
                  <i className="fa fa-play" />
                  <span>Expenses</span>
                </Link>
              </li>

              <li className={nav === "/sales" ? "sidenav-active" : ""}>
                <Link
                  to="/sales"
                  onClick={() => onChangeNav("/sales")}
                  href="#"
                >
                  <i className="fa fa-play" />
                  <span>Sales</span>
                </Link>
              </li>
              {/* <li className={nav === "/top100" ? "sidenav-active" : ""}>
                <Link
                  to="/top100"
                  onClick={() => onChangeNav("/top100")}
                  href="#"
                >
                  <i className="fa fa-list" />
                  <span></span>
                </Link>
              </li> */}
              {/* <li className={nav === "/allvideos" ? "sidenav-active" : ""}>
                <Link
                  to="/allvideos"
                  onClick={() => onChangeNav("/allvideos")}
                  href="#"
                >
                  <i className="fa fa-list" />
                  <span></span>
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

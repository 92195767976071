import React from "react";
import moment from "moment";

export default function ExpenseTable({
  expenses,
  onDeleteExpense,
  selected,
  userData,
  onClickEdit,
}) {
  return (
    <div className="table-view table-responsive">
      <table id="expenses-table" className="table">
        <thead>
          <tr>
            <th>UID</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Paid For</th>
            {userData.role == "Admin" ? <th>Action</th> : null}
          </tr>
        </thead>
        <tbody>
          {expenses
            ? expenses.map((expense) => (
                <tr key={expense.id}>
                  <td>{expense.uid}</td>

                  <td>{ expense.date
                  ? moment(expense.date).format("YYYY-MM-DD")
                  : ""}</td>

                  <td>{expense.amount}</td>
                  <td>{expense.paid_for}</td>
                  {userData.role == "Admin" ? (
                    <td>
                      <span className="expense-action-icons">
                        <i
                          className="fa fa-trash-o"
                          data-toggle="modal"
                          data-target="#confirmationModal"
                          aria-hidden="true"
                          onClick={() => onDeleteExpense(expense)}
                          style={{ margin: "10px" }}
                        />
                        <i
                          className="fa fa-pencil-square-o"
                          data-toggle="modal"
                          data-target="#add-expense-modal"
                          aria-hidden="true"
                          style={{ margin: "10px" }}
                          onClick={() => onClickEdit(expense)}
                        />
                      </span>
                    </td>
                  ) : null}
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
}

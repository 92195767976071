import { expense } from "../../constants/actionTypes";

const initialState = {
  expenseInfo: null,
  expense_details: null,
  allExpenses: null,	
  selectedExpense: null,


};

function expenseReducer(state = initialState, action) {
  switch (action.type) {
    case expense.SELECTED_EXPENSE:
        return { ...state, selectedExpense: action.payload };
    case expense.EXPENSE_INFO:
      return { ...state, expenseInfo: action.payload };

      case expense.EXPENSES:
        return { ...state, allExpenses: action.payload };
    	case expense.DELETE_EXPENSE:
        return { ...state, selectedExpense: action.payload };
      default:
      return state;
  }
}
export default expenseReducer;

import API from "../../api";
import { sale } from "../../constants/actionTypes";
import { loaderState, selectedNav } from "../";
import { goto } from "../../helpers";
import swal from "sweetalert";
import moment from "moment";
import _ from "lodash";

export const getSale = (id) => (dispatch) => {
  API.get(`/sales/${id}`)
    .then((res) => {
      dispatch({ type: sale.SALE_INFO, payload: res.data });
      dispatch(selectedNav("/dashboard"));
      setTimeout(() => {
        dispatch(loaderState(false));
        goto("/dashboard");
      }, 500);
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const resetSales = () => {
  return {
    type: sale.SALES,
    payload: [],
  };
};

export const getSales = (data) => (dispatch, getState) => {
  let filter = data ? "?filter=" + data : "";
  API.get("/sales" + filter)
    .then((res) => {
      //  console.log(res.data)
      // let data = _.filter(res.data.data, user => {
      // 	return user.email != loggedInUser.email && Number(user.isActive) === 1;
      // });
      // if (res.data.code === 200) {
      console.log("res");
      console.log(res.data);

      dispatch({
        type: sale.SALES,
        payload: res.data,
      });
      //  } else {
      //      swal("", res.data.message, "error");
      //     console.log("inside get users")
      //
      //  }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const deleteSale = (id) => (dispatch, getState) => {
  const { allSales } = getState().sale;
  API.delete(`/sales/${id}`)
    .then((res) => {
      _.remove(allSales, (sale) => {
        return sale.id === id;
      });
      dispatch({ type: sale.SALES, payload: allSales });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onDeleteSale = (sales) => (dispatch) => {
  dispatch({ type: sale.DELETE_SALE, payload: sales });
};

export const addSale = (data) => (dispatch, getState) => {
  API.post("/sales", data)
    .then((res) => {
      dispatch(getSales());
      swal("", "Sales added", "success");
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};
export const selectedSale = (s_sale) => {
  return { type: sale.SELECTED_SALE, payload: s_sale };
};

export const updateSalesInfo = (id, data) => (dispatch) => {
  API.patch(`/sales/${id}`, data)
    .then((res) => {
      dispatch(getSales());
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const getSaleInfo = () => (dispatch, getState) => {
  let { paymentInfo } = getState().payment;

  API.get(`/sales/${paymentInfo.id}`)
    .then((res) => {
      dispatch({ type: sale.SALE_INFO, payload: res.data });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

// export const deleteUser = (id) => (dispatch, getState) => {
//   const { allUsers } = getState().users;
//   api
//     .post(`/users/update/${id}`, { isActive: -1 })
//     .then((res) => {
//       _.remove(allUsers, (user) => {
//         return user.id === id;
//       });
//       dispatch({ type: users.USERS, payload: allUsers });
//       dispatch(loaderState(false));
//     })
//     .catch((err) => {
//       swal("", err.message, "error");
//       dispatch(loaderState(false));
//     });
// };

// export const onDeleteUser = (user) => (dispatch) => {
//   dispatch({ type: users.DELETE_USER, payload: user });
// };

// export const getUserManager = () => (dispatch, getState) => {
//   let { userInfo } = getState().user;
//   if (userInfo.manager_id) {
//     API.get(`/managerDetails/${userInfo.manager_id}`)
//       .then((res) => {
//         dispatch({ type: user.MANAGER, payload: res.data });
//         dispatch(loaderState(false));
//       })
//       .catch((err) => {
//         dispatch(loaderState(false));
//         swal("Oops!", err.message, "error");
//       });
//   }
// };

// export const getUserPredictions = () => (dispatch, getState) => {
//   const { userInfo } = getState().user;
//   if (userInfo.balance && userInfo.balance >= 1000) {
//     let data = {
//       starting_funds: Number(userInfo.balance),
//       length: 12,
//     };
//     if (userInfo.special_investor && userInfo.special_investor === true) {
//       data.type = "special";
//       data.percentage_value = userInfo.special_percentage;
//     } else {
//       data.type = "standard";
//     }
//     CAL_API.post("/investment_calculations/calculator", data)
//       .then((res) => {
//         dispatch({ type: user.PREDICTION, payload: res.data });
//       })
//       .catch((err) => {
//         dispatch(loaderState(false));
//         swal("", err.message, "error");
//       });
//   }
// };

//export * from "./address";
//export * from "./banking";
//export * from "./details";

import React from "react";
import moment from "moment";

export default function Payments({ payments }) {
  return (
    <div className="table-view table-responsive">
      <table id="payments-table" className="table">
        <thead>
          <tr>
            {/* <th>UID</th> */}

            <th>Date</th>
            <th>Cheque</th>
            <th>Cash</th>
            <th>Bank Transfer</th>
            <th>Petty Cash</th>
          </tr>
        </thead>
        <tbody>
          {payments
            ? payments.map((payment) => (
                <tr key={payment.id}>
                  {/* <td>{payment.uid}</td> */}

                  <td>{moment(payment.date).format("DD/MM/YYYY")}</td>

                  <td>{payment.cheque}</td>
                  <td>{payment.cash}</td>
                  <td>{payment.bank_transfer}</td>
                  <td>{payment.petty_cash}</td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
}

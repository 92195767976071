import React, { Component } from "react";
import {
  goto,
  initilizeDataTable,
  destroyDataTable,
  isEmptyOrNull,
} from "../../helpers";
import { connect } from "react-redux";
import swal from "sweetalert";
import {
  loaderState,
  getUsers,
  onDeleteUser,
  deleteUser,
  addUser,
  selectedUser,
  updatePersonalInfo,
} from "../../actions";
import UsersComp from "../../components/Users/index";
import $ from "jquery";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      selected: [],
    };
  }

  componentDidMount() {
    initilizeDataTable("users-table");
    this.props.loaderState(true);
    this.props.getUsers();
  }

  componentDidUpdate() {
    initilizeDataTable("users-table");
  }

  onChangeUser = (user) => {
    this.setState({ user });
  };

  onChange = ({ target }) => {
    const { id, value } = target;
    // console.log([id])
    this.setState({ [id]: value });
  };

  onDelete = () => {
    const { selected_user } = this.props;

    this.props.loaderState(true);
    this.props.deleteUser(selected_user.id);
  };

  onDeleteUser = (user) => {
    console.log("__________________");
    console.log(user.id);
    this.setState({ user: user.first_name + " " + user.last_name });
    this.props.selectedUser(user);
    //this.props.onDeleteUser(user);
    // this.props.deleteUser(user.id);
  };
  resetState = () => {
    this.setState({
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      role: null,
      edit: false,
    });
  };
  onClickEdit = (user) => {
    this.setState({ edit: true });
    this.props.selectedUser(user);
    console.log(user);
  };
  onSelectUser = (user) => {
    this.props.selectedUser(user);
  };
  setSelected = (selected) => {
    this.setState({ selected });
  };
  onClickAdd = () => {
    //  this.setState({ edit: false });
    // this.props.selectedUser(null);
    const { selected_user } = this.props;
    let state = { ...this.state };

    let data = {
      first_name: !isEmptyOrNull(state.first_name)
        ? state.first_name
        : selected_user.first_name,
      last_name: !isEmptyOrNull(state.last_name)
        ? state.last_name
        : selected_user.last_name,
      email: !isEmptyOrNull(state.email) ? state.email : selected_user.email,
      role: !isEmptyOrNull(state.role) ? state.role : selected_user.role,
    };
    this.resetState();
    if (state.edit) {
      let id = selected_user.id;
      this.props.loaderState(true);
      this.props.selectedUser(null);
      this.props.updatePersonalInfo(id, data);
    } else {
      data.password = state.password;
      if (
        isEmptyOrNull(data.first_name) ||
        isEmptyOrNull(data.last_name) ||
        isEmptyOrNull(data.password) ||
        isEmptyOrNull(data.email) ||
        isEmptyOrNull(data.role)
      ) {
        swal("", "Fields are required", "info");
      } else {
        this.props.loaderState(true);
        this.props.addUser(data);
        // $(".close").click();
      }
    }
  };

  onClickClose() {
    $(".close").click();
    this.resetState();
    this.props.selectedUser(null);
  }

  onClickSearch = () => {
    const { dateFrom, dateTo } = this.state;
    let filter = {
      where: {
        and: [
          { date: { lte: new Date(dateTo) } },
          { date: { gte: new Date(dateFrom) } },
        ],
      },
    };
    filter = JSON.stringify(filter);
    this.props.loaderState(true);
    this.props.getUsers(filter);
  };

  render() {
    const { selected_user } = this.props;
    const { selected } = this.state;
    let stateData = { ...this.state };
    console.log("----------");
    console.log(stateData);

    destroyDataTable("users-table");

    const { user } = this.state;
    const { users, userData } = this.props;
    return (
      <UsersComp
        user={user}
        users={users}
        userData={userData}
        onDeleteUser={(user) => this.onDeleteUser(user)}
        onChangeUser={(user) => this.onChangeUser(user)}
        onDelete={() => this.onDelete()}
        resetState={() => this.resetState()}
        onChange={(e) => this.onChange(e)}
        onClickAdd={() => this.onClickAdd()}
        stateData={stateData}
        resetState={() => this.resetState()}
        selectedUser={selected_user}
        selected={selected}
        onClickEdit={(user) => this.onClickEdit(user)}
        onClickClose={() => this.onClickClose()}
        onClickSearch={() => this.onClickSearch()}
      />
    );
    // onChange={(e) => onChange(e)}

    // onClickAdd={() => onClickAdd()}
    // onClickClose={() => onClickClose()}
    // resetState={() => resetState()}
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.user.allUsers,
    userData: state.user.loggedInUser,
    selected_user: state.user.selected_user,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getUsers,
  onDeleteUser,
  deleteUser,
  addUser,
  selectedUser,
  updatePersonalInfo,
})(Users);

import React from "react";
import moment from "moment";

const AddPaymentsModal = ({
  selectedPayment,
  onChange,
  onClickAdd,
  onClickClose,
  stateData,
}) => {
  let maxDate = moment().format("YYYY-MM-DD");
  return (
    <div className="modal fade" id="add-payment-modal" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => onClickClose()}
            >
              &times;
            </button>
            <h4 className="modal-title">Enter details of Payments</h4>
          </div>
          <div className="modal-body" style={{ margin: "0 10% 0 10%" }}>
            {/* <div className="form-group">
							<label htmlFor="date">* Date</label>
							<input 
							type="text" 
							className="form-control" 
							id="date" 
							onChange={(e) => onChange(e)} 
						/>
						</div> */}

            <div className="form-group">
              <label htmlFor="date">* Date</label>
              <input
                onChange={(e) => onChange(e)}
                className="form-control"
                type="date"
                id="date"
                max={maxDate}
                value={
                  stateData.date
                    ? moment(stateData.date).format("YYYY-MM-DD")
                    : selectedPayment && selectedPayment.date
                    ? moment(selectedPayment.date).format("YYYY-MM-DD")
                    : ""
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="cheque">* Cheque</label>
              <input
                value={
                  stateData.cheque
                    ? stateData.cheque
                    : selectedPayment && selectedPayment.cheque
                    ? selectedPayment.cheque
                    : ""
                }
                type="text"
                className="form-control"
                id="cheque"
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="cash">* Cash</label>
              <input
                value={
                  stateData.cash
                    ? stateData.cash
                    : selectedPayment && selectedPayment.cash
                    ? selectedPayment.cash
                    : ""
                }
                type="text"
                className="form-control"
                id="cash"
                onChange={(e) => onChange(e)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="bank_transfer">* Bank Transfer</label>
              <input
                value={
                  stateData.bank_transfer
                    ? stateData.bank_transfer
                    : selectedPayment && selectedPayment.bank_transfer
                    ? selectedPayment.bank_transfer
                    : ""
                }
                type="text"
                className="form-control"
                id="bank_transfer"
                onChange={(e) => onChange(e)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="petty_cash">* Petty Cash</label>
              <input
                value={
                  stateData.petty_cash
                    ? stateData.petty_cash
                    : selectedPayment && selectedPayment.petty_cash
                    ? selectedPayment.petty_cash
                    : ""
                }
                type="text"
                className="form-control"
                id="petty_cash"
                onChange={(e) => onChange(e)}
              />
            </div>

            {/* <div className="form-group">
							<label htmlFor="initation-message">Invitation Message Preview</label>
							<textarea
								disabled
								rows="5"
								value="test would like you to access their account at Globuss Software. test uses Globuss Software to help manage their business."
								type="text"
								id="initation-message"
							/>
						</div> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-dismiss="modal"
              onClick={() => onClickAdd()}
            >
              Add
            </button>
            {/* <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => onClickClose()}>
							Close
						</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPaymentsModal;

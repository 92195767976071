import API from "../../api";
import { user } from "../../constants/actionTypes";
import { loaderState, selectedNav } from "../";
import { goto } from "../../helpers";
import swal from "sweetalert";
import moment from "moment";
import _ from "lodash";

export const registerUser = (userData) => (dispatch) => {
  userData.date_time = moment().format();
  userData.status = "Active";
  API.post("/users/signup", userData)
    .then((res) => {
      let data = res.data;
      delete data.password;
      dispatch({ type: user.USER_INFO, payload: data });
      dispatch(selectedNav("/dashboard"));
      setTimeout(() => {
        dispatch(loaderState(false));
        goto("/dashboard");
      }, 500);
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const login = ({ email, password }) => (dispatch) => {
  API.post("/users/login", { email, password })
    .then((res) => {
      if (typeof res.data === "string") {
        dispatch(loaderState(false));
        swal("Oops!", res.data, "error");
      } else {
        dispatch({ type: user.LOGGED_IN_USER_DATA, payload: res.data });

        dispatch(getUser(res.data.id));
      }
    })
    .catch((err) => {
      swal("Oops!", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const getUser = (id) => (dispatch) => {
  API.get(`/users/${id}`)
    .then((res) => {
      dispatch({ type: user.USER_INFO, payload: res.data });
      dispatch(selectedNav("/dashboard"));
      setTimeout(() => {
        dispatch(loaderState(false));
        goto("/dashboard");
      }, 500);
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const getUsers = (data) => (dispatch, getState) => {
  const { loggedInUser } = getState().user;
  let filter = data ? "?filter=" + data : "";
  API.get("/users" + filter)
    .then((res) => {
      console.log(res.data);
      // let data = _.filter(res.data.data, user => {
      // 	return user.email != loggedInUser.email && Number(user.isActive) === 1;
      // });
      // if (res.data.code === 200) {
      dispatch({
        type: user.USERS,
        payload: res.data,
      });
      //  } else {
      //      swal("", res.data.message, "error");
      //     console.log("inside get users")
      //
      //  }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const deleteUser = (id) => (dispatch, getState) => {
  console.log("hogyaaaaaa");
  console.log(id);
  const { allUsers } = getState().user;
  API.delete(`/users/${id}`)
    .then((res) => {
      _.remove(allUsers, (user) => {
        return user.id === id;
      });
      dispatch({ type: user.USERS, payload: allUsers });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onDeleteUser = (users) => (dispatch) => {
  dispatch({ type: user.DELETE_USER, payload: users });
};

export const addUser = (data) => (dispatch) => {
  API.post("/users/signup", data)
    .then((res) => {
      dispatch(getUsers());
      swal("", "User added", "success");
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};
export const selectedUser = (data) => {
  return { type: user.SELECTED_USER, payload: data };
};

export const updatePersonalInfo = (id, data) => (dispatch) => {
  API.patch(`/users/${id}`, data)
    .then((res) => {
      dispatch(getUsers());
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const getUserInfo = () => (dispatch, getState) => {
  let { userInfo } = getState().user;

  API.get(`/users/${userInfo.id}`)
    .then((res) => {
      dispatch({ type: user.USER_INFO, payload: res.data });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

// export const deleteUser = (id) => (dispatch, getState) => {
//   const { allUsers } = getState().users;
//   api
//     .post(`/users/update/${id}`, { isActive: -1 })
//     .then((res) => {
//       _.remove(allUsers, (user) => {
//         return user.id === id;
//       });
//       dispatch({ type: users.USERS, payload: allUsers });
//       dispatch(loaderState(false));
//     })
//     .catch((err) => {
//       swal("", err.message, "error");
//       dispatch(loaderState(false));
//     });
// };

// export const onDeleteUser = (user) => (dispatch) => {
//   dispatch({ type: users.DELETE_USER, payload: user });
// };

// export const getUserManager = () => (dispatch, getState) => {
//   let { userInfo } = getState().user;
//   if (userInfo.manager_id) {
//     API.get(`/managerDetails/${userInfo.manager_id}`)
//       .then((res) => {
//         dispatch({ type: user.MANAGER, payload: res.data });
//         dispatch(loaderState(false));
//       })
//       .catch((err) => {
//         dispatch(loaderState(false));
//         swal("Oops!", err.message, "error");
//       });
//   }
// };

// export const getUserPredictions = () => (dispatch, getState) => {
//   const { userInfo } = getState().user;
//   if (userInfo.balance && userInfo.balance >= 1000) {
//     let data = {
//       starting_funds: Number(userInfo.balance),
//       length: 12,
//     };
//     if (userInfo.special_investor && userInfo.special_investor === true) {
//       data.type = "special";
//       data.percentage_value = userInfo.special_percentage;
//     } else {
//       data.type = "standard";
//     }
//     CAL_API.post("/investment_calculations/calculator", data)
//       .then((res) => {
//         dispatch({ type: user.PREDICTION, payload: res.data });
//       })
//       .catch((err) => {
//         dispatch(loaderState(false));
//         swal("", err.message, "error");
//       });
//   }
// };

export const forgetPassword = (data) => (dispatch) => {
  API.post(`/ClientRegistrations/forgetPassword`, data)
    .then((res) => {
      dispatch(loaderState(false));
      if (res.data.code && res.data.code === 404) {
        swal("Oops!", "User not found", "warning");
      } else {
        swal(
          "",
          "An email is sent to " + data.email + " , please reset you password",
          "success"
        );
      }
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const updatePassword = (data) => (dispatch) => {
  API.post(`/ClientRegistrations/updatePassword`, data)
    .then((res) => {
      dispatch(loaderState(false));
      if (res.data.code && res.data.code === 404) {
        swal("Oops!", "User not found", "warning");
      } else if (res.data.code && res.data.code === 401) {
        swal("Oops!", "Token expired", "warning");
      } else {
        swal("", "Password updated successfully", "success");
      }
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const resetPassword = (data) => (dispatch) => {
  API.post(`/ClientRegistrations/resetPassword`, data)
    .then((res) => {
      dispatch(loaderState(false));
      swal("", "Password reset successfully", "success");
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

//export * from "./address";
//export * from "./banking";
//export * from "./details";

import React from "react";
import moment from "moment";

import Card from "../Common/Card";
import Expenses from "./Expenses";
import Payments from "./Payments";
import Sales from "./Sales";
import ExpenseReport from "./expenseReport";
import SalesReport from "./salesReport";
import PaymentReport from "./paymentReport";
let maxDate = moment().format("YYYY-MM-DD");

export default function Dashboard({
  onChange,
  stateData,
  payments,
  expenses,
  sales,
  onClickSearch,
  paymentsReport,
  salesReport,
  expensesReport,
}) {
  return (
    <>
      {/* <div className="comp-header">Dashboard</div> */}
      {/* <Card> */}
      <div className="row">
        <div className="col-xs-4">
          <div className="form-group">
            <label htmlFor="date">Date From</label>
            <input
              onChange={(e) => onChange(e)}
              className="form-control"
              type="date"
              id="dateFrom"
              max={maxDate}
              value={
                stateData && stateData.dateFrom
                  ? moment(stateData.dateFrom).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
        </div>
        <div className="col-xs-4">
          <div className="form-group">
            <label htmlFor="date">Date To</label>
            <input
              onChange={(e) => onChange(e)}
              className="form-control"
              type="date"
              id="dateTo"
              max={maxDate}
              min={
                stateData && stateData.dateFrom
                  ? moment(stateData.dateFrom).format("YYYY-MM-DD")
                  : null
              }
              value={
                stateData && stateData.dateTo
                  ? moment(stateData.dateTo).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
        </div>
        <div className="col-xs-4">
          <div className="form-group">
            <label htmlFor="date"></label>
            <br />
            <button
              disabled={
                stateData && stateData.dateFrom && stateData.dateTo
                  ? ""
                  : "disabled"
              }
              style={{ marginTop: "5px" }}
              className="btn btn-primary"
              onClick={() => onClickSearch()}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      {/* </Card> */}
      <div className="row" id="dashboard">
        <div className="col-sm-4">
        <div style={{backgroundColor:'#E3F2FD',margin:'10px' , padding:'10px'}}>
            <h5>Payment Report</h5>
            {paymentsReport ? (
              <PaymentReport report={paymentsReport} />
            ) : (
              <div className="no-data">
                <i>No data</i>
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-4" >
        <div style={{backgroundColor:'#F3E5F5',margin:'10px' , padding:'10px'}}>
            <h5>Expenses Report</h5>
            {expensesReport ? (
              <div style={{ height: expensesReport ? "80px" : "auto" }}>
                <ExpenseReport report={expensesReport} />
              </div>
            ) : (
              <div className="no-data">
                <i>No data</i>
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-4">
        <div style={{backgroundColor:'#E8EAF6',margin:'10px' , padding:'10px'}}>
            <h5>Sales Report</h5>
            {salesReport ? (
              <div style={{ height: expensesReport ? "80px" : "auto" }}>
                <SalesReport report={salesReport} />
              </div>
            ) : (
              <div className="no-data">
                <i>No data</i>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row" id="dashboard">
        <div className="col-sm-12">
          <div style={{backgroundColor:'white',margin:'10px' , padding:'10px'}}>
            <h5>Payments</h5>
            <Payments payments={payments} />
          </div>
        </div>
        <div className="col-sm-6">
        <div style={{backgroundColor:'white',margin:'10px' , padding:'10px'}}>
            <h5>Expenses</h5>
            <Expenses expenses={expenses} />
          </div>
        </div>
        <div className="col-sm-6">
        <div style={{backgroundColor:'white',margin:'10px' , padding:'10px'}}>
            <h5>Sales</h5>
            <Sales sales={sales} />
          </div>
        </div>
      </div>
    </>
  );
}

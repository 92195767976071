import React from "react";
import moment from "moment";

export default function Sales({ sales }) {
  return (
    <div className="table-view table-responsive">
      <table id="sales-table" className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Sales Amount</th>
            <th>Margin</th>
            <th>Pofit</th>
          </tr>
        </thead>
        <tbody>
          {sales
            ? sales.map((sale) => (
                <tr key={sale.id}>
                  <td>{moment(sale.date).format("DD/MM/YYYY")}</td>

                  <td>{sale.sales_amount}</td>

                  <td>{sale.margin}</td>
                  <td>{sale.profit}</td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
}

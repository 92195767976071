import React from "react";
import PaymentsTable from "./paymentsTable";
import AddPaymentModal from "./addPaymentsModal";
import ConfirmationModal from "../Common/confirmationModal";
import moment from "moment";
import Card from "../Common/Card";
import PaymentsReport from "../Dashboard/paymentReport";

let maxDate = moment().format("YYYY-MM-DD");

export default function Payments({
  payment,
  onDelete,
  payments,
  paymentData,
  onDeletePayment,
  onChangePayment,
  onChange,
  onClickAdd,
  onClickClose,
  resetState,
  stateData,
  selectedPayment,
  selected,
  userData,
  onClickEdit,
  onClickSearch,
  paymentReport,
}) {
  return (
    <>
      {/* <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Payments</label>
          </div>
          <div className="col-lg-5"></div>
          <div className="col-lg-3" style={{ textAlign: "right" }}>
            <button
              className="btn btn-success btn-sm"
              data-toggle="modal"
              data-target="#add-payment-modal"
            >
              Add Payment
            </button>
          </div>
        </div>
      </div>
      <br /> */}
      <div className="row">
        <div className="col-sm-4">
          <div className="form-group">
            <label htmlFor="date">Date From</label>
            <input
              onChange={(e) => onChange(e)}
              className="form-control"
              type="date"
              id="dateFrom"
              max={maxDate}
              value={
                stateData && stateData.dateFrom
                  ? moment(stateData.dateFrom).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label htmlFor="date">Date To</label>
            <input
              onChange={(e) => onChange(e)}
              className="form-control"
              type="date"
              id="dateTo"
              max={maxDate}
              min={
                stateData && stateData.dateFrom
                  ? moment(stateData.dateFrom).format("YYYY-MM-DD")
                  : null
              }
              value={
                stateData && stateData.dateTo
                  ? moment(stateData.dateTo).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group">
            <label></label>
            <br />
            <button
              disabled={
                stateData && stateData.dateFrom && stateData.dateTo
                  ? ""
                  : "disabled"
              }
              style={{ marginTop: "5px" }}
              className="btn btn-primary"
              onClick={() => onClickSearch()}
            >
              Search
            </button>
          </div>
        </div>
        <div className="col-sm-2" style={{ textAlign: "right" }}>
          <div className="form-group">
            <label></label>
            <button
              style={{ marginTop: "30px" }}
              className="btn btn-success btn-sm"
              data-toggle="modal"
              data-target="#add-payment-modal"
            >
              Add Payment
            </button>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <PaymentsTable
            onClickEdit={(payment) => onClickEdit(payment)}
            userData={userData}
            payments={payments}
            onDeletePayment={(payment) => onDeletePayment(payment)}
            selected={selected}
          />
        </div>
      </div>
      <AddPaymentModal
        onChange={(e) => onChange(e)}
        selectedPayment={selectedPayment}
        stateData={stateData}
        onClickAdd={() => onClickAdd()}
        onClickClose={() => onClickClose()}
        resetState={() => resetState()}
      />
      <ConfirmationModal item={payment} onDelete={() => onDelete()} />
      <div className="row">
        <div className="col-sm-8"></div>
        <div className="col-sm-4">
          <Card>
            <h5>Payments Report</h5>
            <hr />
            {paymentReport ? (
              <PaymentsReport report={paymentReport} />
            ) : (
              <div className="no-data">
                <i>No data</i>
              </div>
            )}
          </Card>
        </div>
      </div>
    </>
  );
}

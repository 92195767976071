import React from "react";
import UsersTable from "./usersTable";
import InviteUserModal from "./inviteUserModal";
import ConfirmationModal from "../Common/confirmationModal";
import moment from "moment";

export default function Users({
  user,
  onDelete,
  users,
  userData,
  onDeleteUser,
  onChangeUser,
  onChange,
  onClickAdd,
  onClickClose,
  resetState,
  stateData,
  selectedUser,
  selected,
  onClickEdit,
  onClickSearch,
}) {
  let isAdmin = userData.role == "Admin" ? true : false;
  // let maxDate = moment().format("YYYY-MM-DD");
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Users</label>
          </div>
          <div className="col-lg-5"></div>
          <div className="col-lg-3" style={{ textAlign: "right" }}>
            <button
              className="btn btn-success btn-sm"
              data-toggle="modal"
              data-target="#invite-user-modal"
            >
              Add User
            </button>
          </div>
        </div>
      </div>
      <br />
      {/* <div className="row">
        <div className="col-sm-4">
          <div className="form-group">
            <label htmlFor="date">Date From</label>
            <input
              onChange={(e) => onChange(e)}
              className="form-control"
              type="date"
              id="dateFrom"
              max={maxDate}
              value={
                stateData && stateData.dateFrom
                  ? moment(stateData.dateFrom).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label htmlFor="date">Date To</label>
            <input
              onChange={(e) => onChange(e)}
              className="form-control"
              type="date"
              id="dateTo"
              max={maxDate}
              min={
                stateData && stateData.dateFrom
                  ? moment(stateData.dateFrom).format("YYYY-MM-DD")
                  : null
              }
              value={
                stateData && stateData.dateTo
                  ? moment(stateData.dateTo).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group">
            <label></label>
            <br />
            <button
              disabled={
                stateData && stateData.dateFrom && stateData.dateTo
                  ? ""
                  : "disabled"
              }
              style={{ marginTop: "5px" }}
              className="btn btn-primary"
              onClick={() => onClickSearch()}
            >
              Search
            </button>
          </div>
        </div>
        <div className="col-sm-2" style={{ textAlign: "right" }}>
          <div className="form-group">
            <label></label>
            <button
              style={{ marginTop: "30px" }}
              className="btn btn-success"
              data-toggle="modal"
              data-target="#invite-user-modal"
            >
              Add User
            </button>
          </div>
        </div>
      </div> */}
      <div className="panel panel-default">
        <div className="panel-body">
          <UsersTable
            isAdmin={isAdmin}
            onDeleteUser={(user) => onDeleteUser(user)}
            users={users}
            selected={selected}
            onClickEdit={(user) => onClickEdit(user)}
          />
        </div>
      </div>
      <InviteUserModal
        onChange={(e) => onChange(e)}
        selectedUser={selectedUser}
        stateData={stateData}
        onClickAdd={() => onClickAdd()}
        onClickClose={() => onClickClose()}
        resetState={() => resetState()}
      />
      <ConfirmationModal item={user} onDelete={() => onDelete()} />
    </>
  );
}

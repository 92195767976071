import React from "react";
import moment from "moment";

export default function PaymentsTable({
  payments,
  onDeletePayment,
  selected,
  userData,
  onClickEdit,
}) {
  console.log("tALBLE");
  console.log(payments);
  return (
    <div className="table-view table-responsive">
      <table id="payments-table" className="table">
        <thead>
          <tr>
            <th>UID</th>

            <th>Date</th>
            <th>Cheque</th>
            <th>Cash</th>
            <th>Bank Transfer</th>
            <th>Petty Cash</th>
            {userData.role == "Admin" ? <th>Action</th> : null}
          </tr>
        </thead>
        <tbody>
          {payments
            ? payments.map((payment) => (
                <tr key={payment.id}>
                  <td>{payment.uid}</td>

                  <td>{ payment.date
                  ? moment(payment.date).format("YYYY-MM-DD")
                  : ""}</td>

                  <td>{payment.cheque}</td>
                  <td>{payment.cash}</td>
                  <td>{payment.bank_transfer}</td>
                  <td>{payment.petty_cash}</td>

                  {userData.role == "Admin" ? (
                    <td>
                      <span className="payment-action-icons">
                        <i
                          className="fa fa-trash-o"
                          data-toggle="modal"
                          data-target="#confirmationModal"
                          aria-hidden="true"
                          onClick={() => onDeletePayment(payment)}
                          style={{ margin: "10px" }}
                        />
                        <i
                          className="fa fa-pencil-square-o"
                          data-toggle="modal"
                          data-target="#add-payment-modal"
                          aria-hidden="true"
                          style={{ margin: "10px" }}
                          onClick={() => onClickEdit(payment)}
                        />
                      </span>
                    </td>
                  ) : null}
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
}

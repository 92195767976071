import React, { Component } from "react";
import {
  goto,
  initilizeDataTable,
  destroyDataTable,
  isEmptyOrNull,
  getExpensesReport,
} from "../../helpers";
import { connect } from "react-redux";
import swal from "sweetalert";
import {
  loaderState,
  getExpenses,
  onDeleteExpense,
  deleteExpense,
  addExpense,
  selectedExpense,
  updateExpenseInfo,
} from "../../actions";
import ExpensesComp from "../../components/Expenses/index";
import Expenses from "../../components/Expenses/index";
import $ from "jquery";
import moment from "moment";

class Expenss extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expense: null,
      selected: [],
    };
  }

  componentDidMount() {
    initilizeDataTable("expenses-table");
    this.props.loaderState(true);
    this.props.getExpenses();
  }

  componentDidUpdate() {
    initilizeDataTable("expenses-table");
  }

  onChangeExpense = (expense) => {
    this.setState({ expense });
  };

  onChange = ({ target }) => {
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  onDelete = () => {
    const { selected_expense } = this.props;
    console.log("selected_expense");
    console.log(selected_expense.id);

    this.props.loaderState(true);
    this.props.deleteExpense(selected_expense.id);
  };

  onDeleteExpense = (expense) => {
    // console.log("payment")

    console.log(expense);
    this.props.selectedExpense(expense);
    this.setState({ expense: expense.amount });
    // this.props.onDeleteExpense(expense.id);
  };
  resetState = () => {
    this.setState({
      date: null,
      amount: null,
      paid_for: null,
      uid: null,
      edit: false,
    });
  };
  onClickEdit = (expense) => {
    this.setState({ edit: true });
    this.props.selectedExpense(expense);
  };
  onSelectExpense = (expense) => {
    this.props.selectedExpense(expense);
  };
  setSelected = (selected) => {
    this.setState({ selected });
  };
  onClickAdd = () => {
    //  this.setState({ edit: false });
    // this.props.selectedUser(null);
    const { loggedInUser, selected_expense } = this.props;

    let state = { ...this.state };

    let data = {
      date: state.date ? moment(state.date) : moment(selected_expense.date),
      amount: state.amount
        ? Number(state.amount)
        : Number(selected_expense.amount),
      paid_for: state.paid_for ? state.paid_for : selected_expense.paid_for,
    };
    this.resetState();
    if (state.edit) {
      let id = selected_expense.id;
      this.props.loaderState(true);
      this.props.selectedExpense(null);
      this.props.updateExpenseInfo(id, data);
    } else {
      data.uid = loggedInUser.uid;
      this.props.loaderState(true);
      this.props.addExpense(data);
    }

    // $(".close").click();
  };

  onClickClose() {
    $(".close").click();
    this.resetState();
    this.props.selectedExpense(null);
  }

  onClickSearch = () => {
    const { dateFrom, dateTo } = this.state;
    let filter = {
      where: {
        and: [
          { date: { lte: new Date(dateTo) } },
          { date: { gte: new Date(dateFrom) } },
        ],
      },
    };
    filter = JSON.stringify(filter);
    this.props.loaderState(true);
    this.props.getExpenses(filter);
  };

  render() {
    const { selected_expense } = this.props;
    const { selected } = this.state;
    let stateData = { ...this.state };

    destroyDataTable("expenses-table");

    const { expense } = this.state;
    const { expenses, expenseData, loggedInUser } = this.props;
    let expensesReport =
      expenses && expenses.length > 0 ? getExpensesReport(expenses) : null;

    return (
      <ExpensesComp
        expense={expense}
        expenses={expenses}
        expenseData={expenseData}
        userData={loggedInUser}
        onDeleteExpense={(expense) => this.onDeleteExpense(expense)}
        onChangeExpense={(expense) => this.onChangeExpense(expense)}
        onDelete={() => this.onDelete()}
        resetState={() => this.resetState()}
        onChange={(e) => this.onChange(e)}
        onClickAdd={() => this.onClickAdd()}
        stateData={stateData}
        resetState={() => this.resetState()}
        selectedExpense={selected_expense}
        selected={selected}
        onClickEdit={(expense) => this.onClickEdit(expense)}
        onClickClose={() => this.onClickClose()}
        onClickSearch={() => this.onClickSearch()}
        expensesReport={expensesReport}
      />
    );
    // onChange={(e) => onChange(e)}

    // onClickAdd={() => onClickAdd()}
    // onClickClose={() => onClickClose()}
    // resetState={() => resetState()}
  }
}

const mapStateToProps = (state) => {
  return {
    expenses: state.expense.allExpenses,
    loggedInUser: state.user.loggedInUser,
    selected_expense: state.expense.selectedExpense,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getExpenses,
  onDeleteExpense,
  deleteExpense,
  addExpense,
  updateExpenseInfo,
  selectedExpense,
})(Expenss);

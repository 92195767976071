import React, { Component } from "react";
import { connect } from "react-redux";
import { selectedNav } from "../../actions";
import { goto } from "../../helpers";

import AppHeaderComp from "../../components/Common/AppHeader";

class AppHeader extends Component {
  onClickLink = (path) => {
    this.props.selectedNav(path);
    goto(path);
  };

  render() {
    //const { loggedInUser } = this.props;

    return (
      <AppHeaderComp
      //  user={loggedInUser}
        goto={(path) => this.onClickLink(path)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //loggedInUser: state.auth.login_user_info,
  };
};

export default connect(mapStateToProps, { selectedNav })(AppHeader);

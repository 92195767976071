import { common } from "../../constants/actionTypes";

const initialState = {
  isLoading: false,
  url: "/",
};

function commonReducer(state = initialState, action) {
  switch (action.type) {
    case common.LOADER:
      return { ...state, isLoading: action.payload };
    case common.SELECTED_URL:
      return { ...state, url: action.payload };
  
    default:
      return state;
  }
}
export default commonReducer;

import React from "react";
import moment from "moment";

const AddExpenseModal = ({
  selectedExpense,
  onChange,
  onClickAdd,
  onClickClose,
  stateData,
}) => {
  let maxDate = moment().format("YYYY-MM-DD");
  return (
    <div className="modal fade" id="add-expense-modal" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => onClickClose()}
            >
              &times;
            </button>
            <h4 className="modal-title">Enter details of Expenses</h4>
          </div>
          <div className="modal-body" style={{ margin: "0 10% 0 10%" }}>
            {/* <div className="form-group">
							<label htmlFor="date">* Date</label>
							<input 
							type="text" 
							className="form-control" 
							id="date" 
							onChange={(e) => onChange(e)} 
						/>
						</div> */}

            <div className="form-group">
              <label htmlFor="date">* Date</label>
              <input
                onChange={(e) => onChange(e)}
                className="form-control"
                type="date"
                id="date"
                value={
                  stateData.date
                    ? moment(stateData.date).format("YYYY-MM-DD")
                    : selectedExpense && selectedExpense.date
                    ? moment(selectedExpense.date).format("YYYY-MM-DD")
                    : ""
                }
                max={maxDate}
              />
            </div>
            <div className="form-group">
              <label htmlFor="amount">* Amount</label>
              <input
                type="text"
                value={
                  stateData.amount
                    ? stateData.amount
                    : selectedExpense && selectedExpense.amount
                    ? selectedExpense.amount
                    : ""
                }
                className="form-control"
                id="amount"
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="paid_for">* Paid For</label>
              <input
                value={
                  stateData.paid_for
                    ? stateData.paid_for
                    : selectedExpense && selectedExpense.paid_for
                    ? selectedExpense.paid_for
                    : ""
                }
                type="text"
                className="form-control"
                id="paid_for"
                onChange={(e) => onChange(e)}
              />
            </div>

            {/* <div className="form-group">
							<label htmlFor="initation-message">Invitation Message Preview</label>
							<textarea
								disabled
								rows="5"
								value="test would like you to access their account at Globuss Software. test uses Globuss Software to help manage their business."
								type="text"
								id="initation-message"
							/>
						</div> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-dismiss="modal"
              onClick={() => onClickAdd()}
            >
              Add
            </button>
            {/* <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => onClickClose()}>
							Close
						</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExpenseModal;

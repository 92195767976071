import _ from "lodash";
import history from "../history";
import { payment, videos } from "../constants/actionTypes";

export const isEmptyOrNull = (data) => {
  let valid = false;
  if (data == null || data === "") {
    valid = true;
  }
  return valid;
};

export const removeNullValues = (object) => {
  let newObj = {};
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      if (!isEmptyOrNull(object[key])) {
        newObj[key] = object[key];
      }
    }
  }
  return newObj;
};

export const goto = (path) => {
  history.push(path);
  history.go(path);
};

export const addCommas = (x) => {
  let number = "0";
  if (x) {
    number = x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  if (!number.includes(".")) {
    number = number + ".00";
  }
  return number;
};

export const filterData = (arr, text) => {
  let filteredData = [];
  let searchtext = text.toLowerCase();
  _.forEach(arr, (object) => {
    let exist = false;
    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        let value = object[key];
        if (!isEmptyOrNull(value)) {
          value = value.toString().toLowerCase().replace(",", "");
          if (value.includes(searchtext)) {
            exist = true;
          }
        }
      }
    }
    if (exist) {
      filteredData.push(object);
    }
  });

  return filteredData;
};

function nanoToMilli(nanoValue) {
  return nanoValue / 1000000;
}

export function getDate(Timestamp) {
  let date = Timestamp.seconds * 1000 + nanoToMilli(Timestamp.nanoseconds);

  return new Date(date);
}

function getReportersData(dataObj) {
  let data = [];
  _.forEach(dataObj, (obj) => {
    let newObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let value = obj[key];
        if (!isEmptyOrNull(value)) {
          if (typeof value === "string") {
            newObj[key] = value;
          } else {
            for (let k in value) {
              if (value.hasOwnProperty(k)) {
                let v = value[k];
                if (!isEmptyOrNull(v)) {
                  newObj[k] = v;
                }
              }
            }
          }
        }
      }
    }
    let newObjj = { ...newObj };
    data.push(newObjj);
  });

  let reporters = "";
  _.forEach(data, (rep) => {
    reporters += rep.username + ", ";
  });
  return reporters;
}

export const initilizeDataTable = (id) => {
  window.$(`#${id}`).DataTable({
    ordering: true,
    select: true,
    searching: true,
    pagingType: "full_numbers",
    language: {
      paginate: {
        next: "&rsaquo;",
        previous: "&lsaquo;",
        first: "&laquo;",
        last: "&raquo;",
      },
    },
    sDom:
      '<"row view-filter"<"col-sm-12"<"pull-left"l><"pull-right"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
  });
};

export const destroyDataTable = (id) => {
  window.$(`#${id}`).DataTable().destroy();
};

export const gendersChartData = (genders) => {
  let data = [];
  let labels = [];
  let backgroundColor = [];
  for (let k in genders) {
    if (genders.hasOwnProperty(k)) {
      let v = genders[k];
      if (!isEmptyOrNull(v)) {
        data.push(v);
        labels.push(k);
      }
    }
  }

  for (var i = 0; i < labels.length; i++) {
    let r = Math.floor(Math.random() * 200);
    let g = Math.floor(Math.random() * 200);
    let b = Math.floor(Math.random() * 200);
    let c = "rgb(" + r + ", " + g + ", " + b + ")";
    backgroundColor.push(c);
  }

  let finalData = {
    labels,
    datasets: [{ data, backgroundColor, borderWidth: 0 }],
  };

  return finalData;
};

export const countriesChartData = (countries) => {
  let data = [];
  let labels = [];
  let backgroundColor = [];
  for (let k in countries) {
    if (countries.hasOwnProperty(k)) {
      let v = countries[k];
      if (!isEmptyOrNull(v)) {
        data.push(v);
        labels.push(k);
      }
    }
  }

  for (var i = 0; i < labels.length; i++) {
    let r = Math.floor(Math.random() * 200);
    let g = Math.floor(Math.random() * 200);
    let b = Math.floor(Math.random() * 200);
    let c = "rgb(" + r + ", " + g + ", " + b + ")";
    backgroundColor.push(c);
  }

  let finalData = {
    labels,
    datasets: [{ data, backgroundColor, borderWidth: 0 }],
  };

  return finalData;
};

export const getPaymentsReport = (payments) => {
  var result = _.map(payments, function (p) {
    return {
      cheque: _.toNumber(p.cheque),
      cash: _.toNumber(p.cash),
      bank_transfer: _.toNumber(p.bank_transfer),
      petty_cash: _.toNumber(p.petty_cash),
    };
  });

  let chequeSum = _.sumBy(result, "cheque");
  let cashSum = _.sumBy(result, "cash");
  let bankTransferSum = _.sumBy(result, "bank_transfer");
  let pettyCashSum = _.sumBy(result, "petty_cash");

  let report = {
    chequeSum,
    cashSum,
    bankTransferSum,
    pettyCashSum,
  };
  return report;
};

export const getExpensesReport = (expenses) => {
  var result = _.map(expenses, function (p) {
    return {
      amount: _.toNumber(p.amount),
    };
  });

  let amountSum = _.sumBy(result, "amount");

  let report = {
    amountSum,
  };
  return report;
};

export const getSalesReport = (sales) => {
  var result = _.map(sales, function (p) {
    return {
      salesAmount: _.toNumber(p.sales_amount),
      margin: _.toNumber(p.margin),
      profit: _.toNumber(p.profit),
    };
  });

  let salesAmountSum = _.sumBy(result, "salesAmount");
  let marginSum = _.sumBy(result, "margin");
  let profitSum = _.sumBy(result, "profit");
  marginSum = marginSum > 0 ? marginSum / result.length : marginSum;
  let report = {
    salesAmountSum,
    marginSum,
    profitSum,
  };
  console.log(salesAmountSum);
  return report;
};

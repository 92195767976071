import React, { Component } from "react";
import { connect } from "react-redux";
import { selectedNav } from "../../actions";
import { goto } from "../../helpers";
import SidebarComp from "../../components/Common/Sidebar";

class Sidebar extends Component {
  onChangeNav = (nav) => {
    this.props.selectedNav(nav);
    // if (nav === "/") {
    //   goto("/");
    // }
  };
  render() {
    const { nav } = this.props;
    return (
      <SidebarComp onChangeNav={(nav) => this.onChangeNav(nav)} nav={nav} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nav: state.common.url,
  };
};
export default connect(mapStateToProps, { selectedNav })(Sidebar);

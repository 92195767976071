import React from "react";
import { Route, Switch } from "react-router-dom";

//containers
import Dashboard from "../containers/Dashboard";
import Users from "../containers/Users";
import Payments from "../containers/Payments";
import Expenses from "../containers/Expenses";
import Sales from "../containers/Sales";


const Routes = () => {
  return (
    <Switch>
      <Route path="/dashboard" component={Dashboard} />
      <Route  path="/users" component={Users} />
      <Route path="/payments" component={Payments} />
      <Route path="/expenses" component={Expenses} />
      <Route path="/sales" component={Sales} />
    </Switch>
  );
};

export default Routes;

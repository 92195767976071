import React from "react";
import { connect } from "react-redux";
import {
  selectedNav,
  loaderState,
  getUsers,
  getStatistics,
  resetPayments,
  resetExpenses,
  resetSales,
  getExpenses,
  getPayments,
  getSales,
} from "../../actions";
import {
  goto,
  isEmptyOrNull,
  initilizeDataTable,
  destroyDataTable,
  getPaymentsReport,
  getExpensesReport,
  getSalesReport,
} from "../../helpers";
import _ from "lodash";
import DashboardComp from "../../components/Dashboard";
import { payment } from "../../constants/actionTypes";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.resetExpenses();
    this.props.resetPayments();
    this.props.resetSales();
    initilizeDataTable("sales-table");
    initilizeDataTable("payments-table");
    initilizeDataTable("expenses-table");
    //  const { users, videos } = this.props;
    this.props.loaderState(false);
  }

  componentDidUpdate() {
    initilizeDataTable("sales-table");
    initilizeDataTable("payments-table");
    initilizeDataTable("expenses-table");
  }

  onChange = ({ target }) => {
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  onGoto = (path) => {
    this.props.loaderState(true);
    this.props.selectedNav(path);
    setTimeout(() => {
      goto(path);
    }, 100);
  };

  onClickSearch = () => {
    const { dateFrom, dateTo } = this.state;
    let filter = {
      where: {
        and: [
          { date: { lte: new Date(dateTo) } },
          { date: { gte: new Date(dateFrom) } },
        ],
      },
    };
    filter = JSON.stringify(filter);
    this.props.loaderState(true);
    this.props.getPayments(filter);
    this.props.getExpenses(filter);
    this.props.getSales(filter);
  };

  render() {
    destroyDataTable("sales-table");
    destroyDataTable("payments-table");
    destroyDataTable("expenses-table");
    const stateData = this.state;
    const { payments, expenses, sales } = this.props;

    let paymentsReport =
      payments && payments.length > 0 ? getPaymentsReport(payments) : null;
    let salesReport = sales && sales.length > 0 ? getSalesReport(sales) : null;
    let expensesReport =
      expenses && expenses.length > 0 ? getExpensesReport(expenses) : null;

    return (
      <DashboardComp
        onClickSearch={() => this.onClickSearch()}
        stateData={stateData}
        onChange={(e) => this.onChange(e)}
        expenses={expenses}
        payments={payments}
        sales={sales}
        paymentsReport={paymentsReport}
        salesReport={salesReport}
        expensesReport={expensesReport}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //users: state.users.allUsers,
    payments: state.payment.allPayments,
    expenses: state.expense.allExpenses,
    sales: state.sale.allSales,
  };
};

export default connect(mapStateToProps, {
  selectedNav,
  loaderState,
  resetPayments,
  resetExpenses,
  resetSales,
  getExpenses,
  getPayments,
  getSales,
})(Dashboard);

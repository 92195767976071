import React from "react";
import moment from "moment";

export default function UsersTable({
  users,
  onDeleteUser,
  selected,
  isAdmin,
  onClickEdit,
}) {
  return (
    <div className="table-view table-responsive">
      <table id="users-table" className="table">
        <thead>
          <tr>
            <th>UID</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Role</th>
            {isAdmin ? <th>Action</th> : null}
          </tr>
        </thead>
        <tbody>
          {users
            ? users.map((user) => (
                <tr key={user.id}>
                  <td>{user.uid}</td>

                  <td>
                    {user.first_name} {user.last_name}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>

                  {isAdmin ? (
                    <td>
                      <span className="user-action-icons">
                        <i
                          className="fa fa-trash-o"
                          data-toggle="modal"
                          data-target="#confirmationModal"
                          aria-hidden="true"
                          onClick={() => onDeleteUser(user)}
                          style={{ margin: "10px" }}
                        />
                        <i
                          className="fa fa-pencil-square-o"
                          data-toggle="modal"
                          data-target="#invite-user-modal"
                          aria-hidden="true"
                          style={{ margin: "10px" }}
                          onClick={() => onClickEdit(user)}
                          //onClick={() => onEditUser(user)}
                        />
                      </span>
                    </td>
                  ) : null}
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
}

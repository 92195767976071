import React from "react";
import moment from "moment";

export default function salesTable({
  sales,
  onDeleteSale,
  selected,
  userData,
  onClickEdit,
}) {
  return (
    <div className="table-view table-responsive">
      <table id="sales-table" className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Sales Amount</th>
            <th>Margin</th>
            <th>Pofit</th>
            {userData.role == "Admin" ? <th>Actions</th> : null}
          </tr>
        </thead>
        <tbody>
          {sales
            ? sales.map((sale) => (
                <tr key={sale.id}>
                  <td>{ sale.date
                  ? moment(sale.date).format("YYYY-MM-DD")
                  : ""}</td>

                  <td>{sale.sales_amount}</td>

                  <td>{sale.margin}</td>
                  <td>{sale.profit}</td>

                  {userData.role == "Admin" ? (
                    <td>
                      <span className="sale-action-icons">
                        <i
                          className="fa fa-trash-o"
                          data-toggle="modal"
                          data-target="#confirmationModal"
                          aria-hidden="true"
                          onClick={() => onDeleteSale(sale)}
                          style={{ margin: "10px" }}
                        />
                        <i
                          className="fa fa-pencil-square-o"
                          data-toggle="modal"
                          data-target="#add-sale-modal"
                          aria-hidden="true"
                          style={{ margin: "10px" }}
                          onClick={() => onClickEdit(sale)}
                        />
                      </span>
                    </td>
                  ) : null}
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
}

import API from "../../api";
import { expense } from "../../constants/actionTypes";
import { loaderState, selectedNav } from "../";
import { goto } from "../../helpers";
import swal from "sweetalert";
import moment from "moment";
import _ from "lodash";

export const getExpense = (id) => (dispatch) => {
  API.get(`/expenses/${id}`)
    .then((res) => {
      dispatch({ type: expense.EXPENSE_INFO, payload: res.data });
      dispatch(selectedNav("/dashboard"));
      setTimeout(() => {
        dispatch(loaderState(false));
        goto("/dashboard");
      }, 500);
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const resetExpenses = () => {
  return {
    type: expense.EXPENSES,
    payload: [],
  };
};

export const getExpenses = (data) => (dispatch, getState) => {
  let filter = data ? "?filter=" + data : "";
  API.get("/expenses" + filter)
    .then((res) => {
      //  console.log(res.data)
      // let data = _.filter(res.data.data, user => {
      // 	return user.email != loggedInUser.email && Number(user.isActive) === 1;
      // });
      // if (res.data.code === 200) {

      dispatch({
        type: expense.EXPENSES,
        payload: res.data,
      });
      //  } else {
      //      swal("", res.data.message, "error");
      //     console.log("inside get users")
      //
      //  }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const deleteExpense = (id) => (dispatch, getState) => {
  const { allExenses } = getState().expense;
  API.delete(`/expenses/${id}`)
    .then((res) => {
      // _.remove(allExenses, (expense) => {
      //   return expense.id === id;
      // });
      // dispatch({ type: expense.EXPENSES, payload: allExenses });
      dispatch(getExpenses());
      // dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onDeleteExpense = (expenses) => (dispatch) => {
  dispatch({ type: expense.DELETE_EXPENSE, payload: expenses });
};

export const addExpense = (data) => (dispatch, getState) => {
  API.post("/expenses", data)
    .then((res) => {
      dispatch(getExpenses());
      swal("", "expense added", "success");
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};
export const selectedExpense = (s_expense) => {
  return { type: expense.SELECTED_EXPENSE, payload: s_expense };
};

export const updateExpenseInfo = (id, data) => (dispatch) => {
  API.patch(`/expenses/${id}`, data)
    .then((res) => {
      dispatch(getExpenses());
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const getExpenseInfo = () => (dispatch, getState) => {
  let { paymentInfo } = getState().payment;

  API.get(`/expenses/${paymentInfo.id}`)
    .then((res) => {
      dispatch({ type: expense.EXPENSE_INFO, payload: res.data });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

// export const deleteUser = (id) => (dispatch, getState) => {
//   const { allUsers } = getState().users;
//   api
//     .post(`/users/update/${id}`, { isActive: -1 })
//     .then((res) => {
//       _.remove(allUsers, (user) => {
//         return user.id === id;
//       });
//       dispatch({ type: users.USERS, payload: allUsers });
//       dispatch(loaderState(false));
//     })
//     .catch((err) => {
//       swal("", err.message, "error");
//       dispatch(loaderState(false));
//     });
// };

// export const onDeleteUser = (user) => (dispatch) => {
//   dispatch({ type: users.DELETE_USER, payload: user });
// };

// export const getUserManager = () => (dispatch, getState) => {
//   let { userInfo } = getState().user;
//   if (userInfo.manager_id) {
//     API.get(`/managerDetails/${userInfo.manager_id}`)
//       .then((res) => {
//         dispatch({ type: user.MANAGER, payload: res.data });
//         dispatch(loaderState(false));
//       })
//       .catch((err) => {
//         dispatch(loaderState(false));
//         swal("Oops!", err.message, "error");
//       });
//   }
// };

// export const getUserPredictions = () => (dispatch, getState) => {
//   const { userInfo } = getState().user;
//   if (userInfo.balance && userInfo.balance >= 1000) {
//     let data = {
//       starting_funds: Number(userInfo.balance),
//       length: 12,
//     };
//     if (userInfo.special_investor && userInfo.special_investor === true) {
//       data.type = "special";
//       data.percentage_value = userInfo.special_percentage;
//     } else {
//       data.type = "standard";
//     }
//     CAL_API.post("/investment_calculations/calculator", data)
//       .then((res) => {
//         dispatch({ type: user.PREDICTION, payload: res.data });
//       })
//       .catch((err) => {
//         dispatch(loaderState(false));
//         swal("", err.message, "error");
//       });
//   }
// };

//export * from "./address";
//export * from "./banking";
//export * from "./details";

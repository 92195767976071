import React from "react";

export default function ConfirmationModal({ item, onDelete }) {
  return (
    <div className="modal fade" id="confirmationModal" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
            <h4 className="modal-title">Delete Data</h4>
          </div>
          <div className="modal-body">
            <p>
              Are you sure you want to delete this {item ? item : "record"}?
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => onDelete()}
              className="btn btn-danger"
              data-dismiss="modal"
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import logo from "../../assets/icons/logo.jpg";

export default function Login({ onClickLogin, onChange }) {
  return (
    <div className="limiter">
    <div className="container-login100">
      <div className="wrap-login100">
        <div className="login100-pic js-tilt" data-tilt>
          <img src={logo} alt="IMG" />
        </div>

        <div className="login100-form ">
          <div className="wrap-input100">
            <input
              className="input100"
              type="text"
              name="email"
              id="email"
              placeholder="Email"
              onChange={(e) => onChange(e)}
            
            />
            <span className="focus-input100"></span>
            <span className="symbol-input100">
              <i className="fa fa-envelope" aria-hidden="true"></i>
            </span>
          </div>

          <div className="wrap-input100">
            <input
              className="input100"
              id="password"
              type="password"
              name="pass"
              placeholder="Password"
              onChange={(e) => onChange(e)}
            />
            <span className="focus-input100"></span>
            <span className="symbol-input100">
              <i className="fa fa-lock" aria-hidden="true"></i>
            </span>
          </div>

          <div className="container-login100-form-btn">
            <button className="login100-form-btn"
               onClick={() => onClickLogin()} >
              Login
            </button>
          </div>

    
        </div>
      </div>
    </div>
  </div>
);
};
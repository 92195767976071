import React from "react";
import moment from "moment";

const AddSalesModal = ({
  selectedSale,
  onChange,
  onClickAdd,
  onClickClose,
  stateData,
}) => {
  let maxDate = moment().format("YYYY-MM-DD");
  return (
    <div className="modal fade" id="add-sale-modal" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => onClickClose()}
            >
              &times;
            </button>
            <h4 className="modal-title">Enter details of Sales</h4>
          </div>
          <div className="modal-body" style={{ margin: "0 10% 0 10%" }}>
            {/* <div className="form-group">
							<label htmlFor="date">* Date</label>
							<input 
							type="text" 
							className="form-control" 
							id="date" 
							onChange={(e) => onChange(e)} 
						/>
						</div> */}

            <div className="form-group">
              <label htmlFor="date">* Date</label>
              <input
                onChange={(e) => onChange(e)}
                className="form-control"
                type="date"
                id="date"
                max={maxDate}
                value={
                  stateData.date
                    ? moment(stateData.date).format("YYYY-MM-DD")
                    : selectedSale && selectedSale.date
                    ? moment(selectedSale.date).format("YYYY-MM-DD")
                    : ""
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="sales_amount">* Sales Amont</label>
              <input
                value={
                  stateData.sales_amount
                    ? stateData.sales_amount
                    : selectedSale && selectedSale.sales_amount
                    ? selectedSale.sales_amount
                    : ""
                }
                type="text"
                className="form-control"
                id="sales_amount"
                onChange={(e) => onChange(e)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="margin">* Margin</label>
              <input
                value={
                  stateData.margin
                    ? stateData.margin
                    : selectedSale && selectedSale.margin
                    ? selectedSale.margin
                    : ""
                }
                type="text"
                className="form-control"
                id="margin"
                onChange={(e) => onChange(e)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="profit">* Profit</label>
              <input
                value={
                  stateData.profit
                    ? stateData.profit
                    : selectedSale && selectedSale.profit
                    ? selectedSale.profit
                    : ""
                }
                type="text"
                className="form-control"
                id="profit"
                onChange={(e) => onChange(e)}
              />
            </div>

            {/* <div className="form-group">
							<label htmlFor="initation-message">Invitation Message Preview</label>
							<textarea
								disabled
								rows="5"
								value="test would like you to access their account at Globuss Software. test uses Globuss Software to help manage their business."
								type="text"
								id="initation-message"
							/>
						</div> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-dismiss="modal"
              onClick={() => onClickAdd()}
            >
              Add
            </button>
            {/* <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => onClickClose()}>
							Close
						</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSalesModal;

export const loggedUser = {
    UERS_INFO: "UERS_INFO",
  };
  
  export const common = {
    LOADER: "LOADER",
    SELECTED_URL: "SELECTED_URL",
    STATISTICS: "STATISTICS",
  };
  export const user = {
    SELECTED_USER: "SELECTED_USER",
    USER_INFO: "USER_INFO",
    DETAILS: "DETAILS",
    LOGGED_IN_USER_DATA: "LOGGED_IN_USER_DATA",
    USERS: "USERS",
    DELETE_USER: "DELETE_USER",
    PERMISSIONS: "PERMISSIONS",
    ORGANIZATION_POSTAL_ADDRESSES: "ORGANIZATION_POSTAL_ADDRESSES",
    ORGANIZATION_PHYSICAL_ADDRESSES: "ORGANIZATION_PHYSICAL_ADDRESSES",
  };

  export const payment = {
    SELECTED_PAYMENT: "SELECTED_PAYMENT",
    PAYMENT_INFO: "PAYMENT_INFO",
    DETAILS: "DETAILS",
    LOGGED_IN_PAYMENT_DATA: "LOGGED_IN_PAYMENT_DATA",
    PAYMENTS: "PAYMENTS",
    DELETE_PAYMENT: "DELETE_PAYMENT",
    PERMISSIONS: "PERMISSIONS",
    ORGANIZATION_POSTAL_ADDRESSES: "ORGANIZATION_POSTAL_ADDRESSES",
    ORGANIZATION_PHYSICAL_ADDRESSES: "ORGANIZATION_PHYSICAL_ADDRESSES",
  };

  export const expense = {
    SELECTED_EXPENSE: "SELECTED_EXPENSE",
    EXPENSE_INFO: "USER_INFO",
    DETAILS: "DETAILS",
    EXPENSES: "EXPENSES",
    DELETE_EXPENSE: "DELETE_EXPENSE",
   
  };


  export const sale = {
    SELECTED_SALE: "SELECTED_SALE",
    SALE_INFO: "SALE_INFO",
    DETAILS: "DETAILS",
    SALES: "SALES",
    DELETE_SALE: "DELETE_SALE",
  };
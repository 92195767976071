import React from "react";

export default function ExpenseReport({ report }) {
  return (
    <>
      <div className="display-inline">
        <div className="field">Amount: </div>
        <div className="value">{report.amountSum}</div>
      </div>
    </>
  );
}

import React from "react";

const InviteUserModal = ({
  selectedUser,
  onChange,
  onClickAdd,
  onClickClose,
  stateData,
}) => {
  console.log(stateData);
  console.log(selectedUser);
  return (
    <div className="modal fade" id="invite-user-modal" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => onClickClose()}
            >
              &times;
            </button>
            <h4 className="modal-title">
              Enter details of the person you want to add
            </h4>
          </div>
          <div className="modal-body" style={{ margin: "0 10% 0 10%" }}>
            <div className="form-group">
              <label htmlFor="first_name">* First Name</label>
              <input
                type="text"
                className="form-control"
                id="first_name"
                onChange={(e) => onChange(e)}
                value={
                  stateData.first_name
                    ? stateData.first_name
                    : selectedUser && selectedUser.first_name
                    ? selectedUser.first_name
                    : ""
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="last_name">* Last Name</label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                onChange={(e) => onChange(e)}
                value={
                  stateData.last_name
                    ? stateData.last_name
                    : selectedUser && selectedUser.last_name
                    ? selectedUser.last_name
                    : ""
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">* Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                onChange={(e) => onChange(e)}
                value={
                  stateData.email
                    ? stateData.email
                    : selectedUser && selectedUser.email
                    ? selectedUser.email
                    : ""
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">* Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                onChange={(e) => onChange(e)}
                value={
                  stateData.password
                    ? stateData.password
                    : selectedUser && selectedUser.password
                    ? selectedUser.password
                    : ""
                }
              />
            </div>

            {/* <div className="form-group">
							<label htmlFor="email">* Password</label>
							<input type="text" className="form-control" id="password"      onChange={(e) => onChange(e)} />
						</div> */}
            <div className="form-group">
              <label htmlFor="user-role">Select list:</label>
              <select
                className="form-control"
                id="role"
                onChange={(e) => onChange(e)}
                value={
                  stateData.role
                    ? stateData.role
                    : selectedUser && selectedUser.role
                    ? selectedUser.role
                    : ""
                }
              >            
                  <option>Select Role</option>

                <option>Admin</option>
                <option>Employee</option>
              </select>
            </div>
           
            {/* <div className="form-group">
							<label htmlFor="initation-message">Invitation Message Preview</label>
							<textarea
								disabled
								rows="5"
								value="test would like you to access their account at Globuss Software. test uses Globuss Software to help manage their business."
								type="text"
								id="initation-message"
							/>
						</div> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-dismiss="modal"
              onClick={() => onClickAdd()}
            >
              Add
            </button>
            {/* <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => onClickClose()}>
							Close
						</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteUserModal;

import React, { Component } from "react";
import {
  goto,
  initilizeDataTable,
  destroyDataTable,
  isEmptyOrNull,
  getPaymentsReport,
} from "../../helpers";
import { connect } from "react-redux";
import swal from "sweetalert";
import {
  loaderState,
  getPayments,
  onDeletePayment,
  deletePayment,
  addPayment,
  selectedPayment,
  updatePaymentInfo,
  selectedUser,
} from "../../actions";
import PaymentsComp from "../../components/Payments/index";
import moment from "moment";
import $ from "jquery";

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: null,
      selected: [],
    };
  }

  componentDidMount() {
    initilizeDataTable("payments-table");
    this.props.loaderState(true);
    this.props.getPayments();
  }

  componentDidUpdate() {
    initilizeDataTable("payments-table");
  }

  onChangePayment = (payment) => {
    this.setState({ payment });
  };

  onChange = ({ target }) => {
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  onDelete = () => {
    const { selected_payment } = this.props;
    console.log("selected_payment");
    console.log(selected_payment.id);

    this.props.loaderState(true);
    this.props.deletePayment(selected_payment.id);
  };

  onDeletePayment = (payment) => {
    console.log("payment");

    console.log(payment);
    this.props.selectedPayment(payment);
    this.setState({ payment: payment.cheque });
    // this.props.onDeletePayment(payment.id);
  };
  resetState = () => {
    this.setState({
      date: null,
      cheque: null,
      cash: null,
      bank_transfer: null,
      petty_cash: null,
      uid: null,
      edit: false,
    });
  };
  onClickEdit = (payment) => {
    this.setState({ edit: true });
    this.props.selectedPayment(payment);
  };
  onSelectPayment = (payment) => {
    this.props.selectedPayment(payment);
  };
  setSelected = (selected) => {
    this.setState({ selected });
  };
  onClickAdd = () => {
    //  this.setState({ edit: false });
    // this.props.selectedUser(null);
    const { loggedInUser, selected_payment } = this.props;

    let state = { ...this.state };

    let data = {
      date: state.date ? moment(state.date) : moment(selected_payment.date),
      cheque: state.cheque
        ? parseFloat(state.cheque)
        : parseFloat(selected_payment.cheque),
      cash: state.cash
        ? parseFloat(state.cash)
        : parseFloat(selected_payment.cash),
      bank_transfer: state.bank_transfer
        ? parseFloat(state.bank_transfer)
        : parseFloat(selected_payment.bank_transfer),
      petty_cash: state.petty_cash
        ? parseFloat(state.petty_cash)
        : parseFloat(selected_payment.petty_cash),
    };
    this.resetState();
    if (state.edit) {
      let id = selected_payment.id;
      this.props.loaderState(true);
      this.props.selectedPayment(null);
      this.props.updatePaymentInfo(id, data);
    } else {
      data.uid = loggedInUser.uid;
      this.props.loaderState(true);
      this.props.addPayment(data);
      // $(".close").click();
    }
    this.resetState();
  };

  onClickClose() {
    $(".close").click();
    this.resetState();
    this.props.selectedPayment(null);
  }

  onClickSearch = () => {
    const { dateFrom, dateTo } = this.state;
    let filter = {
      where: {
        and: [
          { date: { lte: new Date(dateTo) } },
          { date: { gte: new Date(dateFrom) } },
        ],
      },
    };
    filter = JSON.stringify(filter);
    this.props.loaderState(true);
    this.props.getPayments(filter);
  };

  render() {
    const { selected_payment } = this.props;
    const { selected } = this.state;
    let stateData = { ...this.state };

    destroyDataTable("payments-table");

    const { payment } = this.state;
    const { payments, paymentData, loggedInUser } = this.props;

    let paymentReport =
      payments && payments.length > 0 ? getPaymentsReport(payments) : null;
    return (
      <PaymentsComp
        payment={payment}
        payments={payments}
        paymentData={paymentData}
        userData={loggedInUser}
        onDeletePayment={(payment) => this.onDeletePayment(payment)}
        onChangePayment={(payment) => this.onChangePayment(payment)}
        onDelete={() => this.onDelete()}
        resetState={() => this.resetState()}
        onChange={(e) => this.onChange(e)}
        onClickAdd={() => this.onClickAdd()}
        stateData={stateData}
        resetState={() => this.resetState()}
        selectedPayment={selected_payment}
        selected={selected}
        onClickEdit={(payment) => this.onClickEdit(payment)}
        onClickClose={() => this.onClickClose()}
        onClickSearch={() => this.onClickSearch()}
        paymentReport={paymentReport}
      />
    );
    // onChange={(e) => onChange(e)}

    // onClickAdd={() => onClickAdd()}
    // onClickClose={() => onClickClose()}
    // resetState={() => resetState()}
  }
}

const mapStateToProps = (state) => {
  return {
    payments: state.payment.allPayments,
    loggedInUser: state.user.loggedInUser,
    selected_payment: state.payment.selectedPayment,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getPayments,
  onDeletePayment,
  deletePayment,
  addPayment,
  selectedPayment,
  updatePaymentInfo,
})(Payments);

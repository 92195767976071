import React from "react";
import { isEmptyOrNull } from "../../helpers";

export default function Header({ user, goto }) {
  return (
    <div className="app-header">
      <div className="row dropdown">
        <div className="col-lg-2 col-md-2 col-sm-1 col-1"></div>
        <div className="col-lg-7 col-md-7 col-sm-5 col-5" id="user-name"></div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-6">
          <span href="#" className="user-info dropdown">
            <a className="dropdown-toggle" data-toggle="dropdown">
              
              {user && !isEmptyOrNull(user.displayName)
                ? user.displayName
                : "Profile"}
              <i
                style={{ color: "black" }}
                className="fa fa-caret-down"
                aria-hidden="true"
              />
            </a>
            <ul className="dropdown-menu dropdown-menu-right">
              {/* <li>
                <a href="#">Profile</a>
              </li> */}
              <li>
                <a onClick={() => goto("/")}>Logout</a>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  );
}

import { user } from "../../constants/actionTypes";

const initialState = {
  userInfo: null,
  account_details: null,
  allUsers: null,	
  selectedUser: null,
  loggedInUser: null,
  selected_user:null

};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case user.SELECTED_USER:
        return { ...state, selected_user: action.payload };
    case user.USER_INFO:
      return { ...state, userInfo: action.payload };
    case user.DETAILS:
      return { ...state, account_details: action.payload };
      case user.USERS:
        return { ...state, allUsers: action.payload };
    case user.DELETE_USER:
	return { ...state, selectedUser: action.payload };
      case user.LOGGED_IN_USER_DATA:
     return { ...state, loggedInUser: action.payload };
        
      default:
      return state;
  }
}
export default userReducer;

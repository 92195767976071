import { payment } from "../../constants/actionTypes";

const initialState = {
  paymentInfo: null,
  account_details: null,
  allPayments: null,	
  selectedPayment: null,


};

function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case payment.SELECTED_PAYMENT:
        return { ...state, selectedPayment: action.payload };
    case payment.PAYMENT_INFO:
      return { ...state, paymentInfo: action.payload };

      case payment.PAYMENTS:
        return { ...state, allPayments: action.payload };
    	case payment.DELETE_PAYMENT:
        return { ...state, selectedPayment: action.payload };

      default:
      return state;
  }
}
export default paymentReducer;

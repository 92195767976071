import { common } from "../../constants/actionTypes";
import _ from "lodash";
import swal from "sweetalert";

export const loaderState = (state) => {
  return { type: common.LOADER, payload: state };
};

export const selectedNav = (nav) => {
  return { type: common.SELECTED_URL, payload: nav };
};



import React from "react";

export default function SalesReport({ report }) {
  return (
    <>
      <div className="display-inline">
        <div className="field">Sales Amount: </div>
        <div className="value">{report.salesAmountSum}</div>
      </div>
      <div className="display-inline">
        <div className="field">Marin: </div>
        <div className="value">{report.marginSum}</div>
      </div>
      <div className="display-inline">
        <div className="field">Profit: </div>
        <div className="value">{report.profitSum}</div>
      </div>
    </>
  );
}

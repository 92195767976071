import { combineReducers } from "redux";

import commonReducer from "./common";
import usersReducer from "./User";
import paymentReducer from "./Payments";
import expenseReducer from "./Expenses";
import saleReducer from "./Sales";

//import videosReducer from "./videos";

export default combineReducers({
 common: commonReducer,
 user: usersReducer,
 payment:paymentReducer,
 expense:expenseReducer,
 sale:saleReducer
});

import React, { Component } from "react";
import {
  goto,
  initilizeDataTable,
  destroyDataTable,
  isEmptyOrNull,
  getSalesReport,
} from "../../helpers";
import { connect } from "react-redux";
import swal from "sweetalert";
import {
  loaderState,
  getSales,
  onDeleteSale,
  deleteSale,
  addSale,
  selectedSale,
  updateSalesInfo,
} from "../../actions";
import SalesComp from "../../components/Sales/index";
import moment from "moment";
import $ from "jquery";

class Sales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: null,
      selected: [],
    };
  }

  componentDidMount() {
    initilizeDataTable("sales-table");
    this.props.loaderState(true);
    this.props.getSales();
  }

  componentDidUpdate() {
    initilizeDataTable("sales-table");
  }

  onChangePayment = (sale) => {
    this.setState({ sale });
  };

  onChange = ({ target }) => {
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  onDelete = () => {
    const { selected_sale } = this.props;
    //console.log("selectedPayment")
    //  console.log(selectedPayment.id)

    this.props.loaderState(true);
    this.props.deleteSale(selected_sale.id);
  };

  onDeleteSale = (sale) => {
    //console.log("payment")

    //  console.log(payment)
    this.setState({ sale: sale.cheque });
    this.props.selectedSale(sale);
    // this.props.onDeletePayment(sale.id);
  };
  resetState = () => {
    this.setState({
      date: null,
      sales_amount: null,
      margin: null,
      profit: null,
      edit: false,
    });
  };
  onClickEdit = (sale) => {
    this.setState({ edit: true });
    this.props.selectedSale(sale);
  };
  onSelectSale = (sale) => {
    this.props.selectedSale(sale);
  };
  setSelected = (selected) => {
    this.setState({ selected });
  };
  onClickAdd = () => {
    //  this.setState({ edit: false });
    // this.props.selectedUser(null);
    const { selected_sale } = this.props;

    let state = { ...this.state };

    let data = {
      date: state.date ? moment(state.date) : moment(selected_sale.date),
      sales_amount: state.sales_amount
        ? parseFloat(state.sales_amount)
        : parseFloat(selected_sale.sales_amount),
      margin: state.margin
        ? parseFloat(state.margin)
        : parseFloat(selected_sale.margin),
      profit: state.profit
        ? parseFloat(state.profit)
        : parseFloat(selected_sale.profit),
    };
    this.resetState();
    if (state.edit) {
      let id = selected_sale.id;
      this.props.loaderState(true);
      this.props.selectedSale(null);
      this.props.updateSalesInfo(id, data);
    } else {
      this.props.loaderState(true);
      this.props.addSale(data);
      // $(".close").click();
    }
  };

  onClickClose() {
    $(".close").click();
    this.resetState();
    this.props.selectedSale(null);
  }

  onClickSearch = () => {
    const { dateFrom, dateTo } = this.state;
    let filter = {
      where: {
        and: [
          { date: { lte: new Date(dateTo) } },
          { date: { gte: new Date(dateFrom) } },
        ],
      },
    };
    filter = JSON.stringify(filter);
    this.props.loaderState(true);
    this.props.getSales(filter);
  };

  render() {
    const { selected_sale } = this.props;
    const { selected } = this.state;
    let stateData = { ...this.state };

    destroyDataTable("sales-table");

    const { sale } = this.state;
    const { sales, saleData, loggedInUser } = this.props;

    let salesReport = sales && sales.length ? getSalesReport(sales) : null;

    return (
      <SalesComp
        userData={loggedInUser}
        sale={sale}
        sales={sales}
        saleData={saleData}
        onDeleteSale={(sale) => this.onDeleteSale(sale)}
        onChangeSale={(sale) => this.onChangeSale(sale)}
        onDelete={() => this.onDelete()}
        resetState={() => this.resetState()}
        onChange={(e) => this.onChange(e)}
        onClickAdd={() => this.onClickAdd()}
        stateData={stateData}
        resetState={() => this.resetState()}
        selectedSale={selected_sale}
        selected={selected}
        onClickEdit={(sale) => this.onClickEdit(sale)}
        onClickClose={() => this.onClickClose()}
        onClickSearch={() => this.onClickSearch()}
        salesReport={salesReport}
      />
    );
    // onChange={(e) => onChange(e)}

    // onClickAdd={() => onClickAdd()}
    // onClickClose={() => onClickClose()}
    // resetState={() => resetState()}
  }
}

const mapStateToProps = (state) => {
  return {
    sales: state.sale.allSales,
    loggedInUser: state.user.loggedInUser,
    selected_sale: state.sale.selectedSales,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getSales,
  onDeleteSale,
  deleteSale,
  addSale,
  selectedSale,
  updateSalesInfo,
})(Sales);

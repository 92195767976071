import React from "react";
import moment from "moment";

export default function Expenses({ expenses }) {
  return (
    <div className="table-view table-responsive">
      <table id="expenses-table" className="table">
        <thead>
          <tr>
            {/* <th>UID</th> */}
            <th>Date</th>
            <th>Amount</th>
            <th>Paid For</th>
          </tr>
        </thead>
        <tbody>
          {expenses
            ? expenses.map((expense) => (
                <tr key={expense.id}>
                  {/* <td>{expense.uid}</td> */}

                  <td>{moment(expense.date).format("DD/MM/YYYY")}</td>

                  <td>{expense.amount}</td>
                  <td>{expense.paid_for}</td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
}

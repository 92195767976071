import React from "react";

export default function PaymentReport({ report }) {
  return (
    <>
      <div className="display-inline">
        <div className="field">Cheque: </div>
        <div className="value">{report.chequeSum}</div>
      </div>
      <div className="display-inline">
        <div className="field">Cash: </div>
        <div className="value">{report.cashSum}</div>
      </div>
      <div className="display-inline">
        <div className="field">Bank Transfer: </div>
        <div className="value">{report.bankTransferSum}</div>
      </div>
      <div className="display-inline">
        <div className="field">Petty Cash: </div>
        <div className="value">{report.pettyCashSum}</div>
      </div>
    </>
  );
}

import React from "react";
import { css } from "@emotion/core";
import { ScaleLoader } from "react-spinners";

const override = css`
  display: block;
  position: fixed;
  margin-top: 12%;
  margin-bottom: -11%;
  margin-left: 44%;
  z-index: 10001;
`;

class AwesomeComponent extends React.Component {
  render() {
    return (
      <div className="sweet-loading">
        <ScaleLoader
          css={override}
          sizeUnit={"px"}
          height={60}
          width={10}
          radius={2}
          // size={60}
          margin="2px"
          color={"#051e34"}
          loading={this.props.display}
        />
      </div>
    );
  }
}

export default AwesomeComponent;
